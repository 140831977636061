import React from 'react'
// import { Router, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
// import history from '../history';
// import blue from '@material-ui/core/colors/blue';
import 'typeface-titillium-web';
import { SnackbarProvider } from 'notistack';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBrain, faUserTie, faSmile, faIndustry, faEye, faLaptop, faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faLinkedinIn, faSlack } from '@fortawesome/free-brands-svg-icons'

library.add(faBrain, faUserTie, faSmile, faIndustry, faEye, faLaptop, faLanguage, faTwitter, faLinkedinIn, faSlack)

const theme = createMuiTheme({
  palette: {
    // primary: blue,
    primary: {
      main: "#2980b9",
    },
    // secondary: {
    //   main: "#D12F34",
    // },
    // type: 'dark',
    contrastText: '#fff',
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Titillium Web',
  },
  spacing: {
    unit: 4
  },
  // overrides: {
  //   MuiDrawer: {
  //     paperAnchorLeft: {
  //       marginLeft: 64
  //     }
  //   }
  // }
})

const Loading = () => <div>Loading...</div>

const Home = Loadable({
  loader: () => import('routes/home/Home'),
  loading: Loading,
})

const CV = Loadable({
  loader: () => import('routes/pages/CV'),
  loading: Loading,
})

const NL = Loadable({
  loader: () => import('routes/pages/NL'),
  loading: Loading,
})

const Contact = Loadable({
  loader: () => import('routes/pages/Contact'),
  loading: Loading,
})

const Demo = Loadable({
  loader: () => import('routes/demo/Demo'),
  loading: Loading,
})

const CalorieMeter = Loadable({
  loader: () => import('routes/pages/CalorieMeter'),
  loading: Loading,
})

const CMPrivacy = Loadable({
  loader: () => import('routes/pages/CMPrivacy'),
  loading: Loading,
})

const App = () => (
  <MuiThemeProvider theme={theme} >
    <SnackbarProvider maxSnack={3} hideIconVariant preventDuplicate> 
      {/* <Router history={history}> */}
      <Router>
        <Switch>
          {/* <Route exact path="/" component={Login} /> */}
          {/* <Route exact path="/login" component={Login} /> */}
          <Route exact path="/" component={Home} />
          <Route exact path="/cv" component={CV} />
          <Route exact path="/nl" component={NL} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/demo" component={Demo} />
          <Route exact path="/cm" component={CalorieMeter} />
          <Route exact path="/cm/privacy" component={CMPrivacy} />
        </Switch>
      </Router>
    </SnackbarProvider>
  </MuiThemeProvider>

)

export default App
