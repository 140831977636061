const initialState = {
    active: 'OVERVIEW',
    drawerOpen: false
  }
  
  const demo = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_ACTIVE_DEMO':
      return {
        active: action.active
      }
      case 'OPEN_DEMO_DRAWER':
      return {
        drawerOpen: true
      }
      default:
        return state
    }
  }
  
  export default demo
  
